$(document).ready(function () {

    $('body').on('click', '.inbenta-km-button', function () {
        pushInbentaEvent();
    });

    $('body').on('keyup', '.inbenta-km-input.form__input', function(event) {
        if (event.keyCode === 13) {
            pushInbentaEvent();
        }
    });

    var pushInbentaEvent = function() {
        dataLayer.push({
            event: 'AllInOne Inbenta search',
            event_category: 'Interactie',
            event_action: 'Inbenta search',
            event_label: 'Submit',
        });
    }
});
